<template>
    <div>
        <b-card no-body>
            <b-card-body>
                <b-row class="justify-content-center">
                    <b-col cols="auto px-2 border-right" class="text-center">
                        <p
                            class="text-success font-weight-bolder font-medium-5"
                        >
                            1
                        </p>
                        <p class="font-small-4">Activos</p>
                    </b-col>
                    <b-col cols="auto pl-2" class="text-center">
                        <p class="text-danger font-weight-bolder font-medium-5">
                            0
                        </p>
                        <p class="font-small-4">Inactivos</p>
                    </b-col>
                </b-row>
            </b-card-body>

            <tabla-general
                :columns="tableColumns"
                :items="tableItems"
                button-activated
            >
                <template v-slot:boton>
                    <b-button
                        variant="success"
                        pill
                        class="px-75 py-50"
                        @click="abrirSidebarVincularPaciente"
                    >
                        <feather-icon icon="PlusIcon" size="20"></feather-icon>
                    </b-button>
                </template>
            </tabla-general>
        </b-card>
        <!-- sidebars  -->
        <sidebar-vincular-paciente
            ref="refSidebarVincularPaciente"
        ></sidebar-vincular-paciente>
    </div>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    components: {
        SidebarVincularPaciente: () =>
            import("../sidebars/SidebarVincularPaciente.vue"),
    },
    setup(props, context) {
        const tableColumns = ref([
            { key: "id" },
            { key: "nombre", sortable: true },
            { key: "edad", sortable: true },
            { key: "identificacion", label: "Identificación" },
            { key: "desde" },
        ]);

        const tableItems = ref([
            {
                id: 1,
                nombre: "Gian Carlos Galán Sánchez",
                edad: 21,
                identificacion: 1007193317,
                desde: "05 ago. 2022",
            },
        ]);

        function abrirSidebarVincularPaciente() {
            context.refs.refSidebarVincularPaciente.toggle();
        }

        return {
            abrirSidebarVincularPaciente,
            tableColumns,
            tableItems,
        };
    },
};
</script>
